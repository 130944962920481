<template>
  <div class="ListImg">
    <Header></Header>
    <Nav :fromPath="fromPath"></Nav>
    <!-- 顶部 -->
    <!-- 主体部分 -->
    <div class="banner">
      <div class="left">
        <!-- <div class="zhanshi">
          <el-carousel indicator-position="none" style="width: 100%; height: 100%">
            <el-carousel-item>
              <img style="width: 100%; height: 100%" :src="headImg1" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div> -->
        <!-- <div class="children">
          <p class="title">
            <em> {{ titleName }}</em>
          </p>
        </div> -->
        <!-- <div class="line">
          <img :src="headImg2" style="width: 100%" alt="" />
        </div> -->
        <div class="chidlenlist">
          <div class="top">
            <p>首页 > {{ titleName }} > {{ nowType.title }}</p>
          </div>
          <div class="content">
            <p class="hscheck" @click="hscheck()">
              <img src="../images/qiehuan.png" alt="" />
            </p>
            <div class="left">
              <p
                v-for="item in typelist"
                :key="item.id"
                @click="changeType(item)"
                :class="nowType.id == item.id ? 'active' : ''"
              >
                {{ item.title }}
                <img v-show="nowType.id == item.id" src="../images/zzsr33.png" alt="" />
              </p>
            </div>
            <div class="right" v-if="showstyle == 1">
              <div
                v-on:mouseover="changeActive($event, index)"
                v-on:mouseout="removeActive($event)"
                v-for="(item, index) in viewslist"
                :key="item.id"
                @click="changePage(item.id)"
                class="list"
              >
                <img :src="item.imgUrl" alt="" />
                <p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                  {{ item.title }}
                </p>
              </div>
              <div class="fenyeqi">
                <el-pagination
                  v-if="total > 12"
                  style="text-align: end"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage1"
                  :page-size="12"
                  layout="prev, pager, next"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
            <div class="right" v-if="showstyle == 2">
              <div
                class="listitem"
                v-for="(item, index) in viewslist"
                :key="item.id"
                @click="changePage(item)"
              >
                <div class="zuo">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.content }}</p>
                </div>
                <div class="you">
                  <p class="p1">{{ item.createTime }}</p>
                  <div @click="changePage(item)">
                    <img v-if="show != index" src="../images/zzsr44.png" alt="" id="s1" />
                    <div class="yyqx" v-if="show == index" @click="changePage(item)">
                      详情
                    </div>
                  </div>
                </div>
              </div>
              <div class="fenyeqi" v-show="total / 4 > 1">
                <el-pagination
                  style="text-align: end"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage1"
                  :page-size="4"
                  layout="prev, pager, next"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :visibility-height="100"></el-backtop>
    <Footer></Footer>
  </div>
</template>
<script>
// 2、阅读空间、科普世界、家庭教育   样式一样
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Nav from "../components/nav.vue";
import apiPopularizationOfScience from "../api/api_popularization_of_science";
import apiRead from "../api/api_read";
import apiEucation from "../api/api_eucation";
import read_5 from "../images/read_5.png";
import read_6 from "../images/read_6.png";
import eucation_1 from "../images/eucation_1.png";
import eucation_2 from "../images/eucation_2.png";
import popularizationOfScience_1 from "../images/popularizationOfScience_1.png";
import popularizationOfScience_2 from "../images/popularizationOfScience_2.png";

export default {
  name: "ListImg",
  data() {
    return {
      currentPage1: 1,
      titleName: "",
      fromPath: "",
      pageNum: 1,
      pageSize: 12,
      viewType: "",
      viewslist: [],
      headImg1: "",
      headImg2: "",
      total: "",
      typelist: [
        {
          title: "全部",
          id: "",
        },
      ],
      showstyle: 1,
      show: 999,
    };
  },
  components: {
    Header,
    Footer,
    Nav,
  },
  watch: {
    // $route(to, from) {
    //   // 路由发生变化页面刷新
    //   this.$router.go(0);
    // },
  },
  created() {
    const { id, fromPath, title } = this.$route.query;
    this.fromPath = fromPath;
    this.nowType = { id, title };
    this.initData();
  },
  methods: {
    hscheck() {
      if (this.showstyle == 1) {
        this.showstyle = 2;
      } else {
        this.showstyle = 1;
      }
    },
    handleSizeChange(val) {
   
    },
    handleCurrentChange(val) {
     
      this.pageNum = val;
     
      this.initData();
    },
    changeActive($event, index) {
      $event.currentTarget.className = "liactive list";
      this.show = index;
    },
    removeActive($event) {
      $event.currentTarget.className = "list";
    },
    changeType(nowType) {
      this.nowType = nowType;
   
      if (nowType.type == 1) {
        window.open("https://" + nowType.resourcesUrl);
      } else {
        this.initData();
      }
    },
    changePage(id) {
      const fromPath = this.fromPath;
      const title = this.titleName;
      this.$router.push({
        path: "detail",
        query: { id, title, fromPath },
      });
    },
    initData() {
      const nowTypeId = this.nowType != undefined ? this.nowType.id : undefined;
      switch (this.fromPath) {
        case "Eucation":
          // 家庭教育
          this.titleName = "家庭教育";
          this.headImg1 = eucation_1;
          this.headImg2 = eucation_2;
          apiEucation
            .getList({
              pageNum: this.pageNum,
              pageSize: this.pageSize,
            })
            .then((res) => {
            
              if (res.code == 0) {
                this.viewslist = res.rows;
                this.total = res.total;
              }
            });
          break;
        case "PopularizationOfScience":
          // 科普世界
          this.titleName = "科普世界";
          this.headImg1 = popularizationOfScience_1;
          this.headImg2 = popularizationOfScience_2;
          apiPopularizationOfScience.getType().then((res) => {
            if (res.code == 0 && res.data && res.data.length > 0) {
              this.typelist = res.data;
            }
          });
          apiPopularizationOfScience
            .getList({
              type: nowTypeId,
              pageNum: this.pageNum,
              pageSize: this.pageSize,
            })
            .then((res) => {
              if (res.code == 0) {
                this.viewslist = res.data.rows;
                this.total = res.data.total;
              }
            });
          break;
        case "ReadSpace":
          // 阅读空间
          this.titleName = "阅读空间";
          this.headImg1 = read_5;
          this.headImg2 = read_6;
          apiRead.getType().then((res) => {
            if (res.code == 0 && res.data && res.data.length > 0) {
              this.typelist = res.data;
            }
          });
          apiRead
            .getList({
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              resourcesType: nowTypeId,
            })
            .then((res) => {
         
              if (res.code == 0) {
                this.viewslist = res.data.rows;
                this.total = res.data.total;
              }
            });
          break;
      }
    },
  },
};
</script>
<style scope>
.ListImg {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
/* 内容 */
.ListImg .banner {
  width: 100%;
  min-height: 700px;
  height: auto;
  margin: 0px auto 40px;
  display: flex;
  padding-bottom: 50px;
}
.ListImg .banner .left {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.ListImg .banner .left .zhanshi {
  width: 100%;
  height: 300px;
}
.ListImg .banner .left .zhanshi .el-carousel__container {
  height: 100%;
}
.ListImg .banner .left .children .title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 4px;
  color: #000000;
}
.ListImg .banner .left .children .title em {
  display: inline-block;
  width: auto;
  height: 50px;
  margin-top: 10px;
  text-align: center;
  font-style: normal;
  border-bottom: 1px solid #000;
}
.ListImg .banner .left .line {
  width: 100%;
  height: 60px;
}
.ListImg .banner .left .chidlenlist {
  width: 100%;
  min-height: 500px;
  background-color: #fff;
}
.ListImg .banner .left .chidlenlist .top {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
}
.ListImg .banner .left .chidlenlist .top p {
  width: 1200px;
  margin-left: calc((100% - 1200px) / 2);
  margin: 0px auto;
  color: #999;
  font-size: 14px;
  line-height: 50px;
}
.ListImg .banner .left .chidlenlist .content {
  width: 1200px;
  min-height: 400px;
  margin-left: calc((100% - 1200px) / 2);
  border-top: 1px solid #eeeeee;
  margin-top: 50px;
  display: flex;
  position: relative;
}
.ListImg .banner .left .chidlenlist .content .hscheck img {
  width: 20px;
  position: absolute;
  right: 0px;
  top: -28px;
}
.ListImg .banner .left .chidlenlist .content .left {
  width: 30%;
  min-height: 400px;
  height: 100%;
  border-right: 1px solid #eeeeee;
}
.ListImg .banner .left .chidlenlist .content .left p {
  height: 50px;
  line-height: 50px;
  color: #666;
  border-bottom: 1px solid #eeeeee;
  padding: 0px 20px;
  margin: 0px;
}

.ListImg .banner .left .chidlenlist .content .left .active {
  color: #ae703e;
}
.ListImg .banner .left .chidlenlist .content .left img {
  float: right;
  margin-top: 15px;
}
.ListImg .banner .left .chidlenlist .content .right {
  width: 70%;
  height: 100%;
  padding: 30px;
}
.ListImg .banner .left .chidlenlist .content .right .list {
  display: inline-block;
  width: 25%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
.ListImg .banner .left .chidlenlist .content .right .list img {
  width: 120px;
  height: 100px;
}
.ListImg .banner .left .chidlenlist .content .right .liactive img {
  width: 185px;
}
.ListImg .banner .left .chidlenlist .content .right .listitem {
  width: calc(100% - 40px);
  padding: 20px;
  height: 130px;
  background-color: #ffffff;
  border: solid 1px #eeeeee;
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
}

.ListImg .banner .left .chidlenlist .content .right .listitem  .zuo {
  width: 80%;
}
.ListImg .banner .left .chidlenlist .content .right .listitem  .zuo h3 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "MicrosoftYaHei";
}
.ListImg .banner .left .chidlenlist .content .right .listitem  .zuo p {
  color: #999999;
  font-size: 14px;
  line-height: 25px;
}
.ListImg .banner .left .chidlenlist .content .right .listitem  .you {
  width: 20%;
  text-align: center;
  color: #aaaaaa;
}
.ListImg .banner .left .chidlenlist .content .right .listitem  .you .p1 {
  font-size: 12px !important;
  font-size: 28px;
  margin: 25px 0px;
}
</style>
